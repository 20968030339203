<template>
<div class="window" v-if="showContent">
  <h2>Regulamin rezerwacji terminu w kalendarzu online firmy Dream Car Sp. z o.o.</h2>
  <ol>
      <li>Usługa rezerwacji terminu w kalendarzu on-line na stronie firmy Dream Car jest prowadzona i zarządzana przez firmę Dream Car Sp. z .o.o. ul. Stoczniowców 3, 30-709 Kraków.</li>
      <li>Usługa rezerwacji terminu w kalendarzu online umożliwia klientowi rezerwację terminu na wykonanie wybranej usługi w serwisie firmy Dream Car przy ul. Stoczniowców 3, 30-709 Kraków w wybranym dniu oraz godzinie.</li>
      <li>Usługa rezerwacji jest bezpłatna.</li>
      <li>Warunkiem przyjęcia rezerwacji jest poprawne wypełnienie obowiązkowych pól na formularzu rezerwacji.</li>
      <li>Firma Dream Car Sp. z o.o. zastrzega sobie prawo do anulowania lub zmiany zarezerwowanego terminu.</li>
      <li>Spóźnienie dłuższe niż 5 minut powoduje, że wybrany termin wizyty jest anulowany.</li>
      <li>Anulowanie lub zmiana terminu przez klienta odbywa się wyłączenie poprzez kontakt e-mailowy lub telefoniczny z serwisem firmy Dream Car Sp. z o.o..</li>
      <li>Administratorem danych osobowych jest firma Dream Car Sp. z .o.o. ul. Stoczniowców 3, 30-709 Kraków. Dane osobowe przetwarzane będą w celu realizacji usługi. Klient ma prawo dostępu do danych osobowych, prawo do ich zmiany, usunięcia lub ograniczenia przetwarzania.</li>
      <li>Akceptacja regulaminu oznacza zgodę na otrzymywanie na podany numer telefonu lub adres e-mail informacji handlowych, reklamowych i marketingowych.</li>
  </ol>
  <div class="button" v-on:click="closeWindow();">Zamknij</div>
</div>

</template>

<script>
export default {
  name: 'RodoWindow',
  data:function(){
      return{

      }
  },
  props: {
      showContent:Boolean,
      closeWindow:Function
  },
  methods:{

  }
}
</script>

<style scoped>
  .window{
    text-align: left;
    padding:2rem;
    position: absolute;
    top:0;
    left:0;
    width:100%;
    background:#FFF;
    box-sizing: border-box;
    overflow-y: visible;
  }

  .window .button{
    display: inline-block;
    margin:2rem auto;
    padding:0.5rem 2rem;
    background-color:#4f77f3;
    color:#FFF;
    transition: all 1s;

  }

  .window .button:hover{
    background-color:#f71831;
  }

  LI{
      font-weight: normal;
      margin:0.5rem 0;
  }
</style>