<template>
  <div class="stepIndicator" v-if="stage>0 && stage<4">Krok {{stage}} z 3</div>
  <div class="stageBox" v-if="stage==0">
    <h2>Wybierz dział</h2>
    <div class="selectBox">
      <div class="optionBox" v-on:click="pickSection(row)" v-for="row in availableSections" :key="row.id">
        <div class="interior">{{row.label}}</div>
      </div>
    </div>
  </div>
  <div v-if="stage>0">
      <!--<img src="@/assets/backgrounds/section-1.jpg" /> -->
  </div>

  <div class="stageBox" v-if="stage==1">
    <h2>{{userSelection.section.label}}<small class="link" v-on:click="serviceChange();">[ zmień usługę ]</small></h2>
    <div class="dateMonth">{{currentMonth}}</div>
    <div class="sectionDetails"><div style="display: inline-flex;align-items:center;"><img src="@/assets/timer-solid.png" /> &nbsp; &nbsp; Czas trwania usługi {{userSelection.service.displayTime}} minut.</div></div>
    <div class="calendarNaviContainer">
        <div v-if="this.calendarConfig.currentWeek>0" class="calendarNavi left" v-on:click="calendarWeekPrev()">Poprzedni tydzień</div>
        <div class="calendarNavi right" v-on:click="calendarWeekNext()">Następny tydzień</div>
    </div>

    <div class="dateContainer">
        <div class="dateBox"  v-bind:class="{'disabled': dateRow.isWorkDay===false || analyzeDate(dateRow)==0, 'overdue': dateRow.isOverdue ===true}" v-on:click="pickDate(dateRow,);" v-for="dateRow in availableDates" :key="dateRow.id">
          <div class="dayName">{{dateRow.dayOfWeek}}</div>
          <div class="day">{{dateRow.dayNumber}}</div>
          <small v-if="analyzeDate(dateRow)==0">Brak wolnych terminów</small>
        </div>
    </div>

  </div>

  <div class="stageBox" v-if="stage==2">
    <h2>{{userSelection.section.label}} - wybierz dogodny termin<small class="link" v-on:click="dayChange();">[ zmień datę ]</small></h2>

    <div class="slotPicker" v-if="userSelection.section.stationType == 'standard'">
      <div v-on:click="slotPick(slot);" class="slotPick" v-for="slot in availableSlots" :key="slot.number">
        <div class="hourRange">
          <span class="hourDigit fixed" v-bind:class="{ 'occupied': slot.freeSlotCount == 0 }">{{slot.start}}</span>
        </div>
      </div>
    </div>


    <div class="slotPicker" v-if="userSelection.section.stationType == 'fixed'">
          <div class="slotPick" v-for="(hour, index) in calendarConfig.daysFixedRenderer" :key="hour">
            <div class="hourRange">
              <div v-for="slot in userSelection.section.stationRules" v-on:click="slotFixedPick(slot,hour);" :key="slot" v-bind:class="{ 'occupied': fixedSlotValidateOccupation(slot,hour)}" v-show="fixedTimeMatrixTodayLimitsGet(index,slot)" class="hourDigit fixed">{{hour}}:{{slot.fixedStartLabel}}</div>
            </div>
          </div>
    </div>

  </div>

  <div class="stageBox" v-if="stage==100">
    <h2>Czy posiadasz koła lub opony w naszej przechowalni?<small class="link" v-on:click="serviceChange();">[ wróć ]</small></h2>

    <div class="selectBox">
      <div class="optionBox" v-on:click="this.userSelection.service.tireStorage = true; this.stage=1;this.calendarGenerate();">
          <div class="interior">Tak</div>
      </div>

      <div class="optionBox" v-on:click="this.userSelection.service.tireStorage = false; this.stage=1;this.calendarGenerate();">
        <div class="interior">Nie</div>
      </div>

    </div>

    <div>
      <p class="infoWindow">W przypadku rezerwacji do działu <b>Wulkanizacja</b> termin rezerwacji uwarunkowany jest tym, czy posiadasz u nas koła/opony w przechowalni.
        <br/><br/>Jeśli tak, możesz dokonać rezerwacji z 24-godzinnym wyprzedzeniem. W przeciwnym wypadku nie obowiązują żadne limity czasowe.</p>
    </div>

  </div>

  <div class="stageBox" v-if="stage==3 && !rodoShow">
    <form :action="dataHost+'bookCommit.php'" method="POST" @submit="bookingSubmit">
        <h2>Dokończ proces rezerwacji <small class="link" v-on:click="hourChange();">[ zmień godzinę ]</small></h2>
        <div class="bookingSummary">
            <div class="calendar">
              <div class="year">{{userSelection.date.yearNumber}}</div>
              <div class="month">{{monthsName[userSelection.date.monthNumber]}}</div>
              <div class="day">{{userSelection.date.dayNumber}}</div>
              <div class="dayOfWeek">{{userSelection.date.dayOfWeek}}</div>
            </div>
          <div style="flex:auto;">
            <div class="dateMonth">{{userSelection.section.label}}</div>
            <div class="sectionDetails" style="font-size: 1.6rem;margin-bottom:1rem;">{{slotPicked.start}}</div>
            <div class="inputBox">
              <label>Imię i nazwisko*</label>
              <div class="errorForm" v-if="displaySubmitErrors.name">Podaj imię i nazwisko</div>
              <input type="text" v-model="userSelection.user.name" placeholder="Jan Kowalski" />
            </div>

            <div class="inputBox">
              <label>Numer telefonu*</label>
              <div class="errorForm" v-if="displaySubmitErrors.telephone">Podaj numer telefonu</div>
              <input type="text" v-model="userSelection.user.telephone" placeholder="601234567"/>
            </div>

            <div class="inputBox">
              <label>Email*</label>
              <div class="errorForm" v-if="displaySubmitErrors.email">Podaj adres email</div>
              <input type="email" v-model="userSelection.user.email" placeholder="jan.kowalski@poczta.pl"/>
            </div>

            <div class="inputBox">
              <label>Numer rejestracyjny*</label>
              <div class="errorForm" v-if="displaySubmitErrors.registrationPlate">Podaj numer rejestracyjny</div>
              <input type="text" v-model="userSelection.user.registrationPlate" placeholder="KR12345" />
            </div>

          </div>
        </div>


        <div class="radioBox">
          <!--
            <div class="inputBox" v-if="userSelection.section.id == 1" style="display: flex; align-content: center; cursor:pointer;" v-on:click="tireStorageCheck();" v-bind:style="[userSelection.service.tireStorage ? 'color:#4f77f3;' : 'color:#000000;']">
              <img v-if="userSelection.service.tireStorage === true" src="@/assets/inputs/radio-check.png"/> <img v-if="userSelection.service.tireStorage === false" src="@/assets/inputs/radio-uncheck.png"/> &nbsp; Posiadam opony w przechowalni Dream Car
            </div>
          -->
            <div class="errorForm" v-if="displaySubmitErrors.consent">Musisz zaakceptować regulamin</div>
            <div class="inputBox" style="display: flex; align-content: center; cursor:pointer;" v-on:click="consentRadio();" v-bind:style="[userSelection.user.consent ? 'color:#4f77f3;' : 'color:#000000;']">
              <img v-if="userSelection.user.consent === true" src="@/assets/inputs/radio-check.png"/> <img v-if="userSelection.user.consent === false" src="@/assets/inputs/radio-uncheck.png"/> &nbsp; Akceptuję regulamin
            </div>
        </div>
      <div><span v-on:click="rodoShow=true" class="bookingTerms">Regulamin rezerwacji</span></div>
      <input type="submit" value="Zarezerwuj termin" class="bookingConfirm">
    </form>
  </div>
  <div class="stageBox" v-if="stage==4">
      <h2>Twoja rezerwacja została przyjęta</h2>
      <p>Wkrótce otrzymasz wiadomość potwierdzającą dokonanie rezerwacji.</p>
      <p>Przypomnimy Ci o wizycie również dzień przed planowaną datą wykonania usługi.</p>
      <p><a class="bookingConfirm" href="https://dreamcar.pl/">Strona główna Dream Car</a></p>
  </div>
  <RodoWindow :show-content="rodoShow" :close-window="closeRodoWindow"></RodoWindow>
</template>

<script>

import axios from 'axios';
import RodoWindow from "@/components/RodoWindow";

export default {
  name: 'calendarWidget',
  components:{
      RodoWindow
  },
  data:function(){
    return{
      availableDates:{},
      availablePicks:{},
      availableSlots:{},
      freeSlotLog:[],
      calendarConfig: {
          prevDatePointer:0,
          nextDatePointer:0,
          currentWeek: 0,
          daysWeek:[8,9,10,11,12,13,14,15,16,17],
          daysSaturday:[8,9,10,11,12,13],
          daysFixedRenderer:[],
          daysFixedTodayStart:0,
          daysFree:{
            "2023-4-8": true,
            "2023-5-1": true,
            "2023-5-2": true,
            "2023-5-3": true,
            "2023-6-8": true,
            "2023-8-15": true,
            "2023-11-11": true,
            "2023-12-25": true,
            "2023-12-26": true,
            "2024-1-1": true,
            "2024-1-6": true
          }
      },
      daysName:['Niedziela','Poniedziałek','Wtorek','Środa','Czwartek','Piątek','Sobota'],
      monthsName:['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
      currentMonth:'',
      availableSections:[
        {
          id:1,
          label:"Wulkanizacja",
          stationCount: 1,
          stationType:'standard',
          service: [
            { label:"Usługa 1", time:40, displayTime:60, tireStorage:false, additionalHours:24 }
          ]
        },
        {
          id:2,
          label:"Klimatyzacja",
          stationCount: 4,
          stationType:'fixed',
          stationRules:[
              {"stationId":1,"fixedStart":0,"fixedStartLabel":"00"},
              {"stationId":2,"fixedStart":15,"fixedStartLabel":"15"},
              {"stationId":3,"fixedStart":30,"fixedStartLabel":"30"},
              {"stationId":4,"fixedStart":45,"fixedStartLabel":"45"}
          ],
          service: [
            {label:"Usługa 1", time:60, displayTime:60, additionalHours:0 }
          ]
        }
      ],
      dataHost: 'https://rezerwacja.dreamcar.pl/',
      slotPicked:null,
      slotsRemaining:0,
      stage:0,
      userSelection:{
          date:{},
          user:{
              name:'',
              email:'',
              telephone: '',
              registrationPlate:'',
              consent:false
          },
          service:{

          }
      },
      rodoShow: false,
      displaySubmitErrors: {name:false,email:false,telephone:false,registrationPlate:false,consent:false},
    }
  },
  props: {

  },

  methods:{
      closeRodoWindow(){
        this.rodoShow=false;
      },
      consentRadio(){
          if(this.userSelection.user.consent === false){
            this.userSelection.user.consent = true;
          }else{
            this.userSelection.user.consent = false;
          }
      },
      tireStorageCheck(){
        if(this.userSelection.service.tireStorage === false){
          this.userSelection.service.tireStorage = true;
        }else{
          this.userSelection.service.tireStorage = false;
        }

      },
      serviceChange(){
          this.stage = 0;
          this.userSelection.section = {};
          this.freeSlotLog = [];
      },

      dayChange(){
        this.stage = 1;
        this.userSelection.date = {};
        this.availableSlots = {};
      },

      hourChange(){
        this.stage = 2;
        this.userSelection.service = {};
      },

      bookingSubmit(e){
        let checksPassed = true;
        this.displaySubmitErrors = {
            name:false,
            email:false,
            telephone:false,
            registrationPlate:false,
            consent:false
        };
        // Validate form code
        if(this.userSelection.user.name.length<5){
            checksPassed = false;
            this.displaySubmitErrors.name = true;
        }

        if(this.userSelection.user.telephone.length<9){
          checksPassed = false;
          this.displaySubmitErrors.telephone = true;
        }else{
            if(isNaN(this.userSelection.user.telephone)){
                checksPassed = false;
                this.displaySubmitErrors.telephone = true;
            }
        }

        if(this.validateEmail(this.userSelection.user.email)===false){
          checksPassed = false;
          this.displaySubmitErrors.email = true;
        }

        if(this.userSelection.user.registrationPlate.length<3){
          this.displaySubmitErrors.registrationPlate = true;
          checksPassed = false;
        }

        if(this.userSelection.user.consent === false){
          this.displaySubmitErrors.consent = true;
          checksPassed = false;
        }


        if(checksPassed === true){
          let mth = this.userSelection.date.monthNumber+1; if(mth<10) { mth = "0"+mth; }
          let day = this.userSelection.date.dayNumber; if(day<10) { day = "0"+day; }

          let entryData = {
              timeStart: new Date(Date.parse(this.userSelection.date.yearNumber+'-'+mth+'-'+day+' '+this.slotPicked.start+':00')),
              timeEnd: new Date(Date.parse(this.userSelection.date.yearNumber+'-'+mth+'-'+day+' '+this.slotPicked.end+':00')),
              sectionId: { id: this.userSelection.section.id },
              stationId: { id: this.slotPicked.pickedSlot },
              plate: this.userSelection.user.registrationPlate,
              telephone: this.userSelection.user.telephone,
              name: this.userSelection.user.name,
              owner: "DC BOOKING APP",
              details: this.userSelection.user.name+' - '+this.userSelection.user.email,
              other:{
                  dcBooking:true,
                  tireStorage: this.userSelection.service.tireStorage
              }
          };
          this.userSelection.slot = this.slotPicked;
          console.log(entryData);

          axios.post(this.dataHost+"datastream/bookCommit.php",{bookingData:this.userSelection,entryData:entryData}).then(response=>{
            console.log(response.data);
            this.stage = 4;
          });


        }
        e.preventDefault();
      },

      validateEmail(email) {
        let expr = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return expr.test(email);
      },

      getDates(){
        //axios.get(this.dataHost+"datastream/getDates.php").then(response=>{
        //    this.availableDates = response.data;
       //})
      },
      checkFreeSlots(row){
        this.pickDate(row);
      },
      getRecords(date){
        let dayKey = date['dayNumber']+'-'+date['monthNumber']+'-'+date['yearNumber'];
        let output = {};
          axios.post(this.dataHost+"datastream/getSectionACI.php",{date:date,section:this.userSelection.section.id}).then(response=>{
            this.availablePicks = response.data;

            switch (this.userSelection.section.stationType){
              case 'fixed':
                  this.freeSlotLog[dayKey] = this.buildFixedTimeMatrix(date); break;
              default:
                  this.freeSlotLog[dayKey] = this.buildTimeMatrix(date); break;
            }
            return output;
          });

      },
      pickDate(row){
          if(row.isWorkDay === true && row.isOverdue === false && this.analyzeDate(row) > 0) { // If mon-sat and not over time and has free slots
            this.dayChange(); // Resetuj datę
            this.userSelection.date = row;
            this.stage = 2;
            this.getRecords(this.userSelection.date);
          }
      },

      analyzeDate(row){
        let dayKey = row['dayNumber']+'-'+row['monthNumber']+'-'+row['yearNumber'];
        if(row.isWorkDay === true && row.isOverdue === false) {
          if(this.freeSlotLog[dayKey] === undefined) {
            this.freeSlotLog[dayKey] = {freeSlots:1};
            this.getRecords(row);
          }else{
            return this.freeSlotLog[dayKey].freeSlots;
          }
        }
      },

      freeSlotsGet(row){
        let dayKey = row['dayNumber']+'-'+row['monthNumber']+'-'+row['yearNumber'];
        if(this.freeSlotLog[dayKey] !== undefined){
          return this.freeSlotLog[dayKey].freeSlots;
        }
      },

      pickSection(row){
          this.$emit('sectionPicked',row.id);
          this.userSelection.section = row;
          if(row.service.length==1){
            this.userSelection.service = row.service[0];
          }else{
            this.userSelection.service = null;
          }

          //console.log(this.userSelection.section);
          // Conditional window
          switch(this.userSelection.section.id){
            case 1:
              this.stage = 100; // Tire storage case
              break;
            default:
              this.stage = 1;
              this.calendarGenerate();
              break;
          }

          //this.stage=1;
      },
      calendarWeekNext(){
          this.calendarGenerate(this.calendarConfig.nextDatePointer);
          this.calendarConfig.currentWeek++;
      },
      calendarWeekPrev(){
        this.calendarConfig.currentWeek--;
        if(this.calendarConfig.currentWeek == 0){
          this.calendarConfig.prevDatePointer = undefined;
        }
        this.calendarGenerate(this.calendarConfig.prevDatePointer);

      },

      /*
      Calculate calendar start
       */
      calendarGenerate(referenceDate){



          this.availableDates = [];
          let d;
          let today;
          let isToday = false;
          let dow;
          if(referenceDate>0){
            d = referenceDate;
            today = Date.now();
          }else {
            let tmp = new Date();
            let mth = (tmp.getMonth()+1);
            let day = tmp.getDate();
            dow = tmp.getDay();
            if(mth<10){
              mth = "0"+mth;
            }
            if(day<10){
              day = "0"+day;
            }
            d = Date.parse(tmp.getFullYear()+'-'+mth+'-'+day+'T00:00:00Z');
            today = d;
          }

        if(this.userSelection.service.tireStorage === true && typeof(this.userSelection.section.service[0].additionalHours) != "undefined"){
          if(dow == 6){
            this.userSelection.section.service[0].additionalHours += 48;
          }
          if(dow == 0){
            this.userSelection.section.service[0].additionalHours += 24;
          }
          today = d += this.userSelection.section.service[0].additionalHours*3600000;
        }

          let refDate = new Date(d);
          let dayofWeek = refDate.getDay();
          let firstDayOfWeek = new Date(refDate).setDate(refDate.getDate() - dayofWeek);

          //console.log(new Date(firstDayOfWeek));
          this.calendarConfig.prevDatePointer = firstDayOfWeek - 604800000;
          this.calendarConfig.nextDatePointer = firstDayOfWeek + 604800000;


          let additionalHours = 0;


          let isOverdue;
          let datePointer;
          for(let i = 1; i<8; i++){
              //let tmpDate = new Date(firstDayOfWeek);
              datePointer = new Date(firstDayOfWeek + (86400000*i));
              let dateId = datePointer.getFullYear()+"-"+(datePointer.getMonth()+1)+"-"+datePointer.getDate();
              let isWorkDay;
              if(datePointer.getDay() == 0){ isWorkDay = false; } else{ isWorkDay = true;}
              if(today>firstDayOfWeek + (86400000*i)){
                isOverdue = true;
              }else{
                if(refDate.getDate() == datePointer.getDate() && refDate.getMonth() == datePointer.getMonth() && refDate.getFullYear() == datePointer.getFullYear()){
                    if(datePointer.getDay() <6 ){ // Niedz - Pt
                        if(datePointer.getHours()>=17){
                          isOverdue = true;
                        }else{
                          isOverdue = false;
                        }
                    }else{ // Sobota
                      if(datePointer.getHours()>=13){
                        isOverdue = true;
                      }else{
                        isOverdue = false;
                      }
                    }
                }else {
                  isOverdue = false
                }
              }
              if(typeof(referenceDate) == "undefined"){
                  if(refDate.getDate() == datePointer.getDate()){
                    isToday = true;
                  }else{
                    isToday = false;
                  }
              }
              // Dni wykluczone
              if(this.calendarConfig.daysFree[dateId] === true){
                isWorkDay = false;
              }

              if(isToday === true){
                let d = new Date();
                if(datePointer.getDay() <6 ) { // Niedz - Pt
                    if(d.getHours()>=17){
                        isOverdue = true;
                    }
                }else { // Sobota
                  if (d.getHours() >= 13) {
                    isOverdue = true;
                  }
                }

                if(additionalHours){
                  isOverdue = true;
                }
              }

              this.availableDates.push({
                dayNumber:datePointer.getDate(),
                monthNumber:datePointer.getMonth(),
                yearNumber:datePointer.getFullYear(),
                dayOfWeek: this.daysName[datePointer.getDay()],
                dayOfWeekNo:datePointer.getDay(),
                isWorkDay: isWorkDay,
                isOverdue:isOverdue,
                id:dateId,
                today:isToday
              });
          }
          this.currentMonth = this.monthsName[datePointer.getMonth()]+" "+datePointer.getFullYear();
      },
      slotPick(slot){

        if(slot.freeSlotCount>0) {
          for (let i = 1; i < slot.stationData.length; i++) {
            if (slot.stationData[i] === true) {
              slot.pickedSlot = i;
            }
          }
          this.stage = 3;
          window.scrollTo(0,0);
          this.slotPicked = slot;
        }
      },

      slotFixedPick(slot,hour){
        if(this.fixedSlotValidateOccupation(slot,hour) === false) {
          let endHour = hour;
          let endMinutes = slot.fixedStart;
          if(slot.fixedStart+this.userSelection.section.service[0].time > 60){
              endHour++;
              endMinutes = (endMinutes+this.userSelection.section.service[0].time)%60;
          }
          this.slotPicked = {
            number: slot.stationId,
            start:hour+":"+slot.fixedStartLabel,
            end:endHour+":"+endMinutes,
            stationData: {
              stationId:slot.stationId
            },
            pickedSlot:slot.stationId
          }
          this.stage = 3;
          window.scrollTo(0,0);
        }
      },

    // Determine unavailable slots
    fixedTimeMatrixTodayLimitsGet(index,slot){
        if(index == 0 && this.userSelection.date.today && new Date().getHours() == 8){
            if(this.calendarConfig.daysFixedTodayStart >= slot.fixedStart){
              return false;
            }else{
              return true;
            }
        }else{
          let blockLength = Math.ceil(this.userSelection.section.service[0].time/60);
          if(index<(this.calendarConfig.daysFixedRenderer.length - blockLength)) {
            return true;
          }else {
            return this.userSelection.section.service[0].time - slot.fixedStart >= this.userSelection.section.service[0].time;
          }
        }
    },

    buildFixedTimeMatrix: function (date) {
      // Ocuppied counter
      var freeSlots = 0;

      if (date.dayOfWeekNo < 6) {
        this.calendarConfig.daysFixedRenderer = this.calendarConfig.daysWeek;
      } else {
        if (date.dayOfWeekNo == 6) {
          this.calendarConfig.daysFixedRenderer = this.calendarConfig.daysSaturday;
        }
      }

      if (this.userSelection.date.today) {
        let h = new Date().getHours();
        this.calendarConfig.daysFixedTodayStart = new Date().getMinutes();
        let newDaysFixedRenderer = [];
        for (let i = 0; i < this.calendarConfig.daysFixedRenderer.length; i++) {
          if (h < this.calendarConfig.daysFixedRenderer[i]) {
            newDaysFixedRenderer.push(this.calendarConfig.daysFixedRenderer[i]);
          }
        }
        this.calendarConfig.daysFixedRenderer = newDaysFixedRenderer;
      }


      let stationMatrix = [];
      let ocuppiedPointer = [];

      let hS = 480;
      let hM = 0;
      let hMs = 0;
      let hE = 1080;

      for (let station = 1; station <= this.userSelection.section.stationCount; station++) {
        stationMatrix[station] = [];
        ocuppiedPointer[station] = 0;
        hS = 480;
        hM = 0;
        hMs = 0;
        hE = 1080;

        while (hS < hE) {
          if (hM >= 60) {
            hM = 0;
          }
          hMs = hM;
          if (hMs < 10) {
            hMs = "0" + hMs;
          }
          let tStr = Math.floor(hS / 60) + ":" + hMs;
          if (typeof (this.availablePicks[station][tStr]) != "undefined") {
            ocuppiedPointer[station] = this.availablePicks[station][tStr];
          }

          if (ocuppiedPointer[station] > 0) {
            stationMatrix[station][hS] = false;
            ocuppiedPointer[station]--;
          } else {
            stationMatrix[station][hS] = true;
          }
          hS++;
          hM++;
        }
      }
      this.availableSlots = stationMatrix;


      for (let i = 0; i < this.userSelection.section.stationRules.length; i++) {
        for (let j = 0; j < this.calendarConfig.daysFixedRenderer.length; j++) {
          if (this.fixedSlotValidateOccupation(this.userSelection.section.stationRules[i], this.calendarConfig.daysFixedRenderer[j]) === false) {
              freeSlots++; break;
          }
        }
        if(freeSlots>0){ break; }
      }
      return {
        freeSlots: freeSlots //freeSlots
      }
    },

    fixedSlotValidateOccupation(slot,hour){
      let startTime = hour*60+slot.fixedStart;
      let endTime = startTime+this.userSelection.section.service[0].time;
      let stationOccupied = false;
      for(let i = startTime; i<endTime; i++){
          if(this.availableSlots[slot.stationId][i] === false){
            stationOccupied = true;break;
          }
      }
      return stationOccupied;
    },

    buildTimeMatrix(date){
      let stationMatrix = [];
      let ocuppiedPointer = [];

      let hS = 480;
      let hM = 0;
      let hMs = 0;
      let hE = 1080;
      let freeSlots = 0;
      for(let station=1; station<=this.userSelection.section.stationCount; station++){
        stationMatrix[station] = [];
        ocuppiedPointer[station] = 0;
        hS = 480;
        hM = 0;
        hMs = 0;
        hE = 1080;

        while(hS<hE){
          if(hM>=60){ hM = 0;}
          hMs = hM;
          if(hMs<10){ hMs = "0"+hMs; }
          let tStr = Math.floor(hS/60)+":"+hMs;
          //console.log(this.availablePicks[station]);
          if(typeof(this.availablePicks[station][tStr]) != "undefined"){
            ocuppiedPointer[station] = this.availablePicks[station][tStr];
          }

          if(ocuppiedPointer[station]>0){
            stationMatrix[station][hS] = false;
            ocuppiedPointer[station]--;
          }else{
            stationMatrix[station][hS] = true;
          }
          hS++;
          hM++;
        }
      }

      /* Build free slot Martix */
      hS = 480;
      hM = 0;
      hMs = 0;
      hE = 1080;
      let slotNumber = 0;

      if(date.dayOfWeekNo <6 ) { // Niedz - Pt
        hE = 1080;
      }else{ // Sobota
        hE = 840;
      }

      while(hS<hE){
        //console.log(hS);
        this.availableSlots[slotNumber] = this.timeMatrixSlotChecker(
            this.userSelection.section.stationCount,
            stationMatrix,
            this.userSelection.section.service[0].time,
            hS,
            slotNumber);
        hS += this.userSelection.section.service[0].time;
        // Zlicz wolne sloty
        freeSlots += this.availableSlots[slotNumber].freeSlotCount;
        slotNumber++;
      }
      return {
          freeSlots: freeSlots
      }
    },
    timeMatrixSlotChecker(stationCount,stationMatrix,serviceTime,startingTime,slotNumber){
      let serviceStartM = startingTime%60;
      let serviceEndM = (startingTime+serviceTime)%60;
      let flagStationPicked = false;
      if(serviceStartM<10){ serviceStartM = "0"+serviceStartM; }
      if(serviceEndM<10){ serviceEndM = "0"+serviceEndM; }
      let output = {
          stationData:[],
          start: Math.floor(startingTime/60)+":"+serviceStartM,
          end: Math.floor((startingTime+serviceTime)/60)+":"+serviceEndM,
          number:slotNumber,
          freeSlotCount:0
      };

      for(let i=1;i<=stationCount;i++){
        let stationFree = true;
        for(let j=startingTime; j<startingTime+serviceTime; j++){
          if(stationMatrix[i][j] == false){
            stationFree = false;
          }
        }
        if(stationFree){ output.freeSlotCount++; }
        output.stationData[i] = stationFree;
        if(flagStationPicked === false) {
          output.stationId = i;
          flagStationPicked = true;
        }
      }
      output.occupationFactor = Math.round((output.freeSlotCount/stationCount)*100);
      //freeSlotCounter
      return output;
    }

  },

  mounted(){
      this.getDates();
      //document.addEventListener("backbutton", this.historyBackOverride, false);
  }
}
</script>

<style scoped>
  .selectBoxLabel{
      font-size:1.2rem;
      font-weight: bold;
      margin: 0.5rem;
      text-align: left;
  }

  .bookingTerms{
    padding:0.5rem 2rem 0.5rem 3rem;
    background-image: url("@/assets/circle-info-regular.png");
    background-repeat: no-repeat;
    background-position: 0.5rem 50%;
    border-bottom:1px solid #4f77f3;
    color:#4f77f3;
    cursor:pointer;
    transition: all 1s;
  }

  .bookingTerms:hover{
      background-color: #4f77f366;
  }

  .bookingConfirm{
      background-color: #4f77f3;
      border:0;
      display: inline-block;
      text-decoration: none;
      margin:4rem auto 2rem auto;
      padding: 0.75rem 2rem;
      font-size:2rem;
      color:#FFFFFF;
      transition: all 400ms;
      cursor:pointer;
  }

  .bookingSummary{
    display: flex;
    margin-bottom:2rem;
    background-color: #EFEFEF;
  }

  .bookingSummary .calendar{
      width:20vw;
      background-color: #EAEAEA;
      padding:1rem 1rem 3rem 1rem;
  }

  .bookingSummary .calendar .year{
      font-size:2rem;
  }

  .bookingSummary .calendar .month{
      font-size:1.2rem;
  }

  .bookingSummary .calendar .day{
    font-size: 4rem;
    margin:1.5rem 0;
  }

  .bookingSummary .calendar .dayOfWeek{
    font-size:1.4rem;
  }

    .bookingConfirm:hover{
        background-color: #4f77f3DD;
    }

    .errorForm{
      background-color: #efcaca;
      color: #d31616;
      border:1px solid #d31616;
      padding:0.5rem;
      margin:0 0 0.5rem 0;
    }

  .selectBox{
    display: flex;
    padding:0.5rem;
    background:#FFF;
    border-radius: 0.25rem;
    margin:0.5rem;
    transition: all 400ms;
  }

  .selectBox .optionBox{
    flex:1 1;
    background-image: url("@/assets/backgrounds/buttonGradientBg.jpg");
    background-size: cover;
    padding:0.25rem;
    margin:0.5rem;
    cursor: pointer;
  }

  .selectBox .optionBox .interior{
      font-size:1.2rem;
      background-color: #FFF;
      color:#000;
      text-align: center;
      padding:1rem;
      transition: all 800ms;
  }

  .selectBox .optionBox .interior:hover{
      background-color: #FFFFFF00;
      color:#FFF;
  }

  .dateContainer{
    display: flex;
    margin: 0 0 4rem 0;
  }

  .dateBox{
      flex:1 1;
      background-color: #EAEAEA;
      padding:1rem 0.5rem;
      margin:0 0.25rem 0 0;
      text-align: center;
      cursor: pointer;
      transition: all 400ms;
  }

  .dateBox.overdue{
      opacity:0.3;
      cursor: not-allowed;
  }

  .dateBox:hover{
      background-color: #4f77f333;
  }

  .dateBox.disabled{
      flex:0.5 0.5;
      color:#FFF;
      background-color: #f71831;
      cursor: not-allowed;
  }

  .dateBox:last-of-type{
      margin:0;
  }

  .dateBox .day{
    font-size:2rem;
  }

  .dateBox .dayName{

  }

  .dateMonth{
      text-align: center;
      background-color: #383838;
      background-image: url("@/assets/backgrounds/buttonGradientBg.jpg");
      background-size: cover;
      padding:0.5rem;
      font-size:1.6rem;
      color:#EEE;
  }

  .sectionDetails{
    background-color: #000;
    display: block;
    color:#AAA;
    padding:0.5rem 2rem;
    margin:0 0 4rem 0;
  }

  .calendarNaviContainer{
      display: flex;
      margin:0 0 0.25rem 0;
  }

  .calendarNavi{
    flex:1 1;
    text-align: center;
    background-color: #DDD;
    padding:1rem 0.25rem;
    margin:0.5rem 0 0 0;
    font-size:1.2rem;
    color:#000000;
    cursor:pointer;
    transition: all 400ms;
    background-repeat: no-repeat;
  }

  .calendarNavi.left{
    background-image: url("@/assets/arrows/left-solid.png");
    background-size: 25px;
    background-position: 10% 50%;
  }

  .calendarNavi.right{
      background-image: url("@/assets/arrows/right-solid.png");
      background-size: 25px;
      background-position: 90% 50%;
  }

  .calendarNavi:first-of-type{
      border-right:0.25rem solid #FFF;
  }

  .calendarNavi:hover{
    background-color: #BBB;
  }

  .optionBox:hover{
    background-color: #E6E6E6;
  }
  .slotPicker{

  }
  .slotPick{
      display: flex;
      flex:auto;
      padding:0;
      text-align: center;
      border-bottom:none;
      border-radius: 0.25rem;
      margin:0 0.5rem 0 0.5rem;
      box-sizing: border-box;
      transition: all 400ms;
      background-color: #FEFEFE;
      cursor: pointer;
      align-content: center;
      align-items: center;
  }


  .slotPick .hourRange{
      flex:auto;
      font-size:1.6rem;
  }

  .slotPick .hourRange .hourDigit{
      display: inline-block;
      border:1px solid #DDD;
      padding:0.5rem;
      border-radius: 0.25rem;
      text-align:center;
      margin:0 0.5rem;
  }

  .slotPick .hourRange .hourDigit.fixed{
    width:10rem;
    margin:1rem 0.5rem;
  }

  .slotPick .hourRange .hourDigit.occupied{
    cursor: not-allowed;
    background-color: #f71831;
    color:#FFFFFF;
  }

  .slotPick .hourRange .hourDigit:hover{
    background-color: #4f77f333;
  }

  .slotPick .details{
      font-size:1rem;
      background-color: #F2F2F2;
      padding: 0.25rem 2rem;
      border-radius: 0.25rem;
      color:#000;
  }

  .slotPick .details.lots{
    border-bottom: 4px solid #5dcb03;
  }

  .slotPick .details.meds{
      border-bottom: 4px solid #d99112;
  }

  .slotPick .details.none{
    border-bottom: 4px solid #f71831;
  }

  .slotPick:nth-child(odd){
      background-color: transparent;
  }


  .slotPick.picked{
      background-color: #4f77f3;
      color:#FFF;
      cursor: default;
  }

  .slotPick.occupied{
      cursor: not-allowed;
  }

  .inputBox{
      padding:0.5rem;
      margin:0.5rem 1rem;
      text-align: left;
  }

  .inputBox IMG{
      width:18px;
      height:18px;
  }

  .inputBox LABEL{
      display: block;
      color:#000000;
      font-size:1.2rem;
      margin-bottom:0.2rem;
  }

  .inputBox INPUT[type=text], .inputBox INPUT[type=email]{
      width:100%;
      padding:1rem 0.5rem;
      display: block;
      border:1px solid #EAEAEA;
      border-radius: 0.25rem;
      box-sizing: border-box;
  }

  .infoWindow{
    background-color: #f7f8ff;
    color:#4f77f3;
    border:1px solid #4f77f3;
    padding:1rem;
    margin:1rem;
    border-radius: 0.25rem;
    font-weight:normal;
  }

  ::placeholder{
      color:#EAEAEA;
  }

  @media screen and (max-width: 640px) {
      .dateContainer{
        flex-direction: column;
      }

    .dateBox{
        margin:0 0 0.5rem 0;
    }

    .calendarNavi.left{
        background-position: 6% 50%;
        background-size: 16px;
    }

    .calendarNavi.right{
      background-position: 94% 50%;
      background-size: 16px;
    }

    .bookingSummary{
      flex-direction: column;
    }

    .bookingSummary .calendar{
      width:auto;
    }

    .selectBox{
      flex-direction: column;
    }

    .slotPick{
      display: block;
    }

    .slotPick .hourRange .hourDigit.fixed{
      margin: 1rem 0;
      width: 100%;
      box-sizing: border-box;
    }


  }

</style>