<template>
  <head>
    <link rel="shortcut icon" type="image/png" href="@/assets/favicon.png"/>
  </head>
  <div class="pageHeader">
      <img alt="Dream Car Logo" class="logo" src="./assets/logo.png">
  </div>

  <div class="widgetContainer disable-select">
      <CalendarWidget />
  </div>
</template>

<script>

import CalendarWidget from "@/components/CalendarWidget";



export default {
  name: 'App',
  components: {
    CalendarWidget
  },
  mounted(){
    window.onbeforeunload  = function() {
      return false;
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,700;1,400&display=swap');
BODY{
  margin:0;
  padding:0;
  background-color: #F8F8F8;
}
#app {
  font-family: 'Barlow Condensed', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0 0 5rem 0;
  position: relative;
  min-height: calc(100vh - 5rem);
  background-image: url("@/assets/backgrounds/uiBg.jpg");
  background-size: cover;
  background-attachment: fixed;
}


.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.widgetContainer{
  max-width:50vw;
  min-width:50vw;
  margin:5rem auto 5rem auto;
  border-radius: 0.5rem;
  background-color: #FFF;
  -webkit-box-shadow: 0px 0px 12px -2px rgba(66, 68, 90, 0.5);
  -moz-box-shadow: 0px 0px 12px -2px rgba(66, 68, 90, 0.5);
  box-shadow: 0px 0px 12px -2px rgba(66, 68, 90, 0.5);
  border:1px solid #BBB;
  padding:0 1rem 2rem 1rem;
  position: relative;
}

.widgetContainer .stepIndicator{
    position:absolute;
    top:0;right:0;
    background-color: #000;
    border-radius:0 0.5rem 0 0;
    padding:0.25rem 1rem;
    color:#EEE;
}

.widgetContainer H2{
    font-size:2rem;
    color:#000000;
    margin:2rem auto 4rem auto;
    text-transform: uppercase;
}

.widgetContainer H2 SMALL{
    font-size:1.1rem;
    margin:0.25rem 0 0 0;
    display: block;
    color:#4f77f3;
    transition: all 1s;
}

.widgetContainer H2 SMALL:hover{
    color:#f71831;
}

.widgetContainer H2 SMALL.link{
    cursor: pointer;
}

.pageHeader{
    background-color: #FFF;
    -webkit-box-shadow: 0px 3px 24px -12px rgba(66, 68, 90, 1);
    -moz-box-shadow: 0px 3px 24px -12px rgba(66, 68, 90, 1);
    box-shadow: 0px 3px 24px -12px rgba(66, 68, 90, 1);
    background-image: url("@/assets/headerBottom.jpg");
    background-repeat: no-repeat;
    background-position: 0% 100%;
    border-bottom:1px solid #BBB;
}

.pageHeader .logo{
    width:12vw;
    margin:1rem auto;
}

.radioBox{
    margin:0 0 2rem 0;
    padding:2rem 0;
    background-color: #FAFAFA;
}

@media screen and (max-width: 640px) {
  .pageHeader .logo{
      width:250px;
      margin:0.5rem auto;
  }


  .widgetContainer{
    max-width: 98vw;
    margin:0 auto;
  }

}

</style>
